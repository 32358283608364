<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card elevation="2" width="400">
        <v-card-title class="primary--text">
          <v-row class="pa-2" justify="center" align="center">
            <v-avatar>
              <v-img
                :src="'/images/abonten-logo.png'"
                contain
                alt="logo"
                height="54"
              ></v-img>
            </v-avatar>
            abonten
          </v-row>
        </v-card-title>
        <v-card-subtitle class="text-h6 mt-2">Set New Password</v-card-subtitle>
        <v-card-text>
          <v-row>
            <p class="px-2 py-1">Please enter new matching password.</p>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                type="password"
                color="secondary"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                v-model="password"
                :rules="[...passwordRules]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                color="secondary"
                prepend-icon="mdi-lock"
                name="confirmPassword"
                label="Confirm Password"
                v-model="confirmPassword"
                :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPwd = !showPwd"
                :type="showPwd ? 'text' : 'password'"
                :rules="[...confirmPasswordRules, ...passwordsMatch]"
                @blur="passwordsMatch"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="submitForm"> Continue </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showPwd: false,

      password: null,
      confirmPassword: null,

      passwordRules: [
        (v) => !!v || "Password cannot be Empty",
        (v) => (v && /\d/.test(v)) || "At least one digit",
        (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
        (v) =>
          (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
      ],
      confirmPasswordRules: [
        (v) => !!v || "Password cannot be Empty",
        (v) => (v && /\d/.test(v)) || "At least one digit",
        (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
        (v) =>
          (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
      ],
    };
  },
  computed: {
    passwordsMatch() {
      return () =>
        this.password === this.confirmPassword || "Passwords do not Match";
    },
  },

  methods: {
    submitForm() {},
  },
};
</script>

<style scoped>
.container {
  padding: 0;
  margin: 0;
  background: white;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-card__title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 30px;
}
.v-card__subtitle {
  padding: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: black !important;
}
</style>
